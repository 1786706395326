import api from "./api";

const sendMail = async (params) => {
  return await api.servicePost("api/mail", params ? params : {});
};

const calendarItems = async () => {
  return await api.serviceGet("api/calendar");
};

const service = {
  sendMail,
  calendarItems,
};

export default service;
