<template>
  <div class="page-content">
    <Map v-bind:coordinates="globalContact.location.coordinates" />
    <Breadcrumb v-bind:data="[{ label: 'Kontakt' }]" />
    <div class="section-full content-inner contact-style-1">
      <div class="container">
        <div class="row">
          <!-- Left part start -->
          <div class="col-lg-4">
            <SideBar v-bind:globalContact="globalContact" />
          </div>
          <div class="col-lg-8">
            <div class="p-a30 bg-gray clearfix m-b30">
              <h2>Kontaktformular</h2>
              <div class="message" v-show="showMessage == true">
                Vielen Dank für deine Nachricht.<br />
                Wir werden uns schnellstmöglich bei dir melden.
              </div>
              <form method="post" class="dzForm">
                <input type="hidden" value="Contact" name="dzToDo" />
                <div class="row">
                  <div class="col-lg-12">
                    <h3>Sektion</h3>
                    <div class="input-group">
                      <select
                        v-model="selectedSectionId"
                        name="widget"
                        @change="handleSectionSelect($event.target.value)"
                      >
                        <option
                          :key="item.slug"
                          v-for="item in sections"
                          :value="item.slug"
                        >
                          {{ item.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div v-if="sectionTeams.length > 0" class="col-lg-12">
                    <h3>Teams</h3>
                    <div class="input-group">
                      <select
                        v-model="selectedTeamId"
                        name="widget"
                        @change="handleTeamSelect($event.target.value)"
                      >
                        <option value="global" selected>
                          Allgemeiner Kontakt
                        </option>
                        <option
                          :key="item.id"
                          v-for="item in sectionTeams"
                          :value="item.id"
                        >
                          {{ item.team }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div v-if="selectedTeamId !== 'global'" class="col-lg-12">
                    <h3>Team Ansprechpartner</h3>
                    <div class="input-group">
                      <select
                        v-model="selectedContactId"
                        name="widget"
                        @change="handleContactSelect($event)"
                      >
                        <option
                          :key="item.id"
                          v-for="item in getTeamContacts(selectedTeamId)"
                          :value="item.id"
                        >
                          {{ item.contact_person }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    v-if="
                      sectionContacs.length > 0 && selectedTeamId === 'global'
                    "
                    class="col-lg-12"
                  >
                    <h3>Ansprechpartner</h3>
                    <div class="input-group">
                      <select
                        v-model="selectedContactId"
                        name="widget"
                        @change="handleContactSelect($event)"
                      >
                        <option
                          :key="item.id"
                          v-for="item in sectionContacs"
                          :value="item.id"
                        >
                          {{ item.contact_person }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <h3>Nachricht</h3>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <div class="input-group">
                        <input
                          name="dzName"
                          type="text"
                          required
                          class="form-control"
                          placeholder="* Dein Name"
                          v-model="form.name"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <div class="input-group">
                        <input
                          name="dzEmail"
                          type="email"
                          class="form-control"
                          required
                          placeholder="* Deine E-Mail Adresse"
                          v-model="form.email"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <div class="input-group">
                        <input
                          v-model="form.phone"
                          name="dzOther[Phone]"
                          type="text"
                          required
                          class="form-control"
                          placeholder="Deine Telefonnummer"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <div class="input-group">
                        <input
                          name="dzOther[Subject]"
                          type="text"
                          required
                          class="form-control"
                          placeholder="* Dein Betreff"
                          v-model="form.subject"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <div class="input-group">
                        <textarea
                          name="dzMessage"
                          rows="4"
                          class="form-control"
                          required
                          placeholder="* Deine Nachricht..."
                          v-model="form.message"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <div class="input-group">
                        <div id="captcha"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <div class="input-group">
                        <div id="captcha"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <button
                      @click="submitForm()"
                      name="submit"
                      type="button"
                      value="Submit"
                      class="site-button"
                      :disabled="!canSubmit()"
                    >
                      <span>Absenden</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Map from "../components/contact/Map.vue";
import SideBar from "../components/contact/SideBar.vue";
import Breadcrumb from "../components/misc/Breadcrumb.vue";
import * as EmailValidator from "email-validator";
import { WidgetInstance } from "friendly-challenge";

import contact from "../lib/api/contacts";
import service from "../lib/api/service";

export default defineComponent({
  components: { Map, Breadcrumb, SideBar },
  data() {
    return {
      globalContact: {
        location: {
          coordinates: [],
        },
      },
      params: {},
      selectedSection: null,
      sections: [],
      sectionsContacts: [],
      sectionContacs: [],
      sectionTeams: [],
      selectedSectionId: null,
      selectedContactId: null,
      selectedTeamId: null,
      currentContact: {},
      form: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      },
    };
  },
  async mounted() {
    const contactResult = await contact.getGlobalContact();
    this.globalContact = contactResult.data;
    this.getSections();
    this.selectedSectionId = "global";
    this.selectedTeamId = "global";

    const that = this;
    function doneCallback(solution) {
      that.captureIsValid = solution;
    }

    const element = document.querySelector("#captcha");
    const options = {
      doneCallback: doneCallback,
      sitekey: "FCMKP25R6CV9CN4P",
    };

    if (!this.captureIsValid) {
      const widget = new WidgetInstance(element, options);
      widget.start();
    }

    if (this.$route.params.section) {
      this.params.section = this.$route.params.section;
    }

    if (this.$route.params.id) {
      this.params.id = this.$route.params.id;
    }

    if (this.$route.params.team) {
      this.params.team = this.$route.params.team;
    }
  },
  watch: {
    selectedSectionId(value) {
      const items = this.sections.find((item) => {
        return item.slug === value;
      });

      if (items && items.contacts && items.contacts[0]) {
        if (this.params.id) {
          this.selectedContactId = this.params.id;
          this.params.id = null;
        } else {
          this.selectedContactId = items.contacts[0]["id"];
        }
      }
    },
    selectedContactId(value) {
      contact.getContactById(value).then((res) => {
        this.currentContact = res.data.shift();
      });
    },
  },
  methods: {
    nl2br(str, is_xhtml) {
      if (typeof str === "undefined" || str === null) {
        return "";
      }
      var breakTag =
        is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
      return (str + "").replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        "$1" + breakTag + "$2"
      );
    },

    async submitForm() {
      const config = {
        to_email: this.currentContact.email,
        to_name: "TSV WUSTROW - " + this.currentContact.contact_person,
        from_email: "no-reply@tsv.myportal.de",
        from_name: "TSV WUSTROW - no-reply",
        subject: "Formularauswerung TSV Wustrow - " + this.form.subject,
        template_id: 3924339,
        params: {
          subject: this.form.subject,
          name: this.form.name,
          mail: this.form.email,
          phone: this.form.phone,
          message: this.nl2br(this.form.message),
        },
      };

      await service.sendMail(config);

      this.form = {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      };

      this.showMessage = true;
      setTimeout(() => {
        this.showMessage = false;
      }, 10000);
    },

    getTeamContacts(teamId) {
      if (this.sectionTeams) {
        const team = this.sectionTeams.find((team) => {
          return team.id === parseInt(teamId) && team.contacts;
        });
        return team ? team.contacts : [];
      }

      return [];
    },
    handleTeamSelect(value) {
      const team = this.sectionTeams.find((team) => {
        return team.id === parseInt(value) && team.contacts;
      });

      this.selectedContactId =
        event.target.value !== "global"
          ? team.contacts[0].id
          : this.sectionContacs[0].id;
    },
    handleSectionSelect(value) {
      const items = this.sections.find((item) => {
        return item.slug === value;
      });

      this.sectionContacs = items.contacts;
      this.sectionTeams = items.teams;

      if (this.params.team) {
        this.handleTeamSelect(this.params.team);
        this.selectedTeamId = this.params.team;
      } else {
        this.selectedTeamId = "global";
      }
      //this.selectedTeamId = "global";
    },
    canSubmit() {
      return (
        this.currentContact &&
        this.currentContact.id &&
        EmailValidator.validate(this.form.email) &&
        this.form.name.length > 3 &&
        this.form.subject.length > 3 &&
        this.form.message.length > 5 &&
        this.captureIsValid
      );
    },
    getSections: async function () {
      let result = [];
      result.push({
        label: "Allgemeiner Kontakt",
        contacts: [],
        slug: "global",
      });
      const items = await contact.getSectionsWithGlobalContact();
      Object.keys(items).map((item) => {
        result.push({
          label: items[item].title,
          contacts: items[item].contacts,
          teams: items[item].teams,
          slug: item,
        });
      });

      this.sections = result;

      if (this.params.section) {
        this.handleSectionSelect(this.params.section);
        this.selectedSectionId = this.params.section;
        this.params.section = null;
      }
    },
  },
});
</script>

<style scoped lang="scss">
.breadcrumb-row {
  margin-top: -5px;
  background: #262626;
}
select {
  height: 50px;
  font-size: 16px;
  padding: 10px 20px;
  width: 100%;
  border: solid 1px rgb(225, 230, 235);
  color: rgb(73, 80, 87);
  outline: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 12px;
  background-color: white;
}

button:disabled {
  opacity: 0.5;
}
.message {
  text-align: center;
  background-color: lightgreen;
  line-height: 170%;
  padding: 10px;
  font-weight: bold;
}
</style>
