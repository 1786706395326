<template>
  <div>
    <iframe
      :src="
        'https://maps.google.com/maps?q=' +
        getCoordiantes() +
        '&t=&z=14&ie=UTF8&iwloc=&output=embed'
      "
      width="100%"
      height="450"
      frameborder="0"
      style="border: 0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  methods: {
    getCoordiantes() {
      return this.coordinates[1] + "%2C" + this.coordinates[0];
    },
  },
  props: {
    coordinates: [],
  },
});
</script>
