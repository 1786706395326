<template>
  <div
    v-if="this.globalContact"
    class="p-a30 m-b30 border-1 contact-area align-self-stretch"
  >
    <h2 class="m-b10">Kontakt</h2>
    <p>Fragen oder Anregungen? Bleib mit uns in Kontakt.</p>
    <ul class="no-margin">
      <li class="icon-bx-wraper left m-b30">
        <div class="icon-bx-xs bg-primary">
          <a href="#" class="icon-cell"><i class="ti-location-pin"></i></a>
        </div>
        <div class="icon-content">
          <h6 class="text-uppercase m-tb0 dlab-tilte">Adresse:</h6>
          <p>{{ globalContact.address }}</p>
        </div>
      </li>
      <li class="icon-bx-wraper left m-b30">
        <div class="icon-bx-xs bg-primary">
          <a href="#" class="icon-cell"><i class="ti-email"></i></a>
        </div>
        <div class="icon-content">
          <h6 class="text-uppercase m-tb0 dlab-tilte">Email:</h6>
          <a :href="globalContact.email">{{ globalContact.email }}</a>
        </div>
      </li>
      <li class="icon-bx-wraper left">
        <div class="icon-bx-xs bg-primary">
          <a href="#" class="icon-cell"><i class="ti-mobile"></i></a>
        </div>
        <div class="icon-content">
          <h6 class="text-uppercase m-tb0 dlab-tilte">Telefon:</h6>
          <p>
            <a :href="'tel:' + globalContact.phone">{{
              globalContact.phone
            }}</a>
          </p>
        </div>
      </li>
    </ul>
    <div class="m-t20">
      <ul class="social-icon dez-border social-icon-lg">
        <li v-if="globalContact.facebook_url">
          <a
            :href="globalContact.facebook_url"
            target="_blank"
            class="fa fa-facebook bg-primary"
          ></a>
        </li>
        <li v-if="globalContact.instagram_url">
          <a
            :href="globalContact.instagram_url"
            target="_blank"
            class="fa fa-instagram bg-primary"
          ></a>
        </li>
        <li v-if="globalContact.twitter_url">
          <a
            :href="globalContact.twitter_url"
            target="_blank"
            class="fa fa-twitter bg-primary"
          ></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    globalContact: {},
  },
});
</script>

<style lang="scss" scoped>
.social-icon {
  li {
    margin-right: 5px;
  }
}
</style>
